import { showVueToast, capitalize } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
export default {
  filters: {
    capitalize
  },
  components: {
    Location: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Location'),
    Cash: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Cash'),
    Jam: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Jam'),
    Koper: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Koper'),
    Folder: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Folder'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    User: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Person'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    ShareAndroid: () => import(/* webpackChunkName: "icon" */ '@/components/icons/ShareAndroid'),
    LinkIcon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/LinkIcon'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    TextField: () => import(/* webpackChunkName: "DropDown" */ '@/components/forms/TextField'),
    Apply: () => import(/* webpackChunkName: "Upload" */ '@/views/Career/Apply/Apply')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  data() {
    return {
      visibleApplyJob: false,
      payloads: {
        name: null,
        email: null,
        currentCity: null,
        phoneNumber: null
      },
      seletedFile: {},
      error: null,
      isReadyToSend: false,
      isLoading: false,
      selectedFile: null,
      apiImg: {
        uploadUrl: '',
        key: ''
      },
      typeImg: '',
      fileName: '',
      response: { image: '' }
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('career', ['vacancyDetail']),
    baseDomain() {
      return process.env.VUE_APP_BASE_DOMAIN
    },
    path() {
      return this.$route.fullPath
    },
    url() {
      if (process.env.NODE_ENV === 'beta') return 'http://g2academy-ui-beta.s3-website-ap-southeast-1.amazonaws.com' + this.path
      return 'https://www' + this.baseDomain + this.path
    },
    vacancyId() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.getVacancyInfo()
  },
  methods: {
    ...mapActions('career', ['getVacancyDetail', 'applyJob']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['getFormToken']),
    getVacancyInfo() {
      this.showLoading()
      this.getVacancyDetail({
        vacancyId: this.vacancyId
      }).then(() => {
        this.hideLoading()
      })
    },
    toggleApplyJob() {
      this.visibleApplyJob = !this.visibleApplyJob
    },
    goBack() {
      this.$router.push('/career')
    },
    async copyLink() {
      try {
        await navigator.clipboard.writeText('Check this out: ' + this.url)
        await showVueToast('Copy link successful!', 'success', 2000)
      } catch (err) {
        await showVueToast('Copy link failed!', 'error', 2000)
      }
    }
  }
}
